import Vue from 'vue'
import App from './App'
import Router from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLocalStorage from 'vue-localstorage'
import vmodal from 'vue-js-modal'
import VueSweetalert2 from 'vue-sweetalert2'
// import VueGoogleApi from 'vue-google-api'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'

import { store } from './vuex/store'

import Registar from '@/components/Registar'
import Login from '@/components/Login'
import Recuperar from '@/components/Recuperar'
import UpdateSenha from '@/components/UpdateSenha'
import Home from '@/components/Home'
import Contactos from '@/components/Contactos'
import ContactosCriar from '@/components/ContactosCriar'
import Calendarios from '@/components/Calendarios'
import Packs from '@/components/Packs'
import Templates from '@/components/Templates'
import Newsletter from '@/components/Newsletter'
import Definicoes from '@/components/Definicoes'
import Utilizadores from '@/components/Utilizadores'
import UtilizadoresCriar from '@/components/UtilizadoresCriar'
import Empresa from '@/components/Empresa'
import Planos from '@/components/Planos'
import PlanosHitorico from '@/components/PlanosHitorico'
import Historico from '@/components/Historico'
import Aniversarios from '@/components/Aniversarios'
import NotFound from '@/components/NotFound'
import MetaDados from '@/components/MetaDados'
import evento from '@/components/Evento'

import Balizas from '@/components/Balizas'
import BalizasCriar from '@/components/BalizasCriar'
import Balizaslandia from '@/components/Balizaslandia'
import balizaslandiaLivre from '@/components/BalizaslandiaLivre'
import BalizaslandiaCriar from '@/components/BalizaslandiaCriar'
import Distribuicao from '@/components/distribuicao'
import Pagamentos from '@/components/pagamentos'
import Monitores from '@/components/Monitores'
import MonitoresCriar from '@/components/MonitoresCriar'
import Monitores1 from '@/components/monitor1'

import Relatorios from '@/components/relatorios'

import semPermissoes from './tpl/semPermissoes'
import semResultados from './tpl/semResultados'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(VueAxios, axios)
Vue.use(Router)
Vue.use(VueLocalStorage)
Vue.use(vmodal, { dialog: true })
Vue.use(VueSweetalert2)
Vue.use(VueMask)

/*
const config = {
  apiKey: 'AIzaSyAzxfRqYqY0sPmlNSOqoPTBefwMT8LtTCA',
  clientId: '474655251318-v77abs2e6bjhbs8betjn592tad5cb68v.apps.googleusercontent.com',
  scope: 'space_separated_scopes'
}
Vue.use(VueGoogleApi, config)
*/

Vue.component('semPermissoes', semPermissoes)
Vue.component('semResultados', semResultados)

Vue.use(VCalendar)

if (window.location.hostname.indexOf('localhost') !== -1) {
  Vue.axios.defaults.baseURL = 'https://app.agendx.com/'
} else {
  Vue.axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/'
}

Vue.router = new Router({
  routes: [
    {
      path: '/registar',
      name: 'registar',
      component: Registar,
      meta: { auth: false, redirect: '/home' }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { auth: false, redirect: '/home' }
    },
    {
      path: '/recuperar',
      name: 'recuperar',
      component: Recuperar,
      meta: { auth: false, redirect: '/home' }
    },
    {
      path: '/update-senha',
      name: 'UpdateSenha',
      component: UpdateSenha,
      meta: { auth: false, redirect: '/home' }
    },
    {
      path: '/meta/contactos',
      name: 'metaContactos',
      component: MetaDados,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/meta/eventos',
      name: 'metaEventos',
      component: MetaDados,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos',
      name: 'contactos',
      component: Contactos,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos/criar',
      name: 'contactosCriar',
      component: ContactosCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos/editar/:id',
      name: 'contactosEditar',
      component: ContactosCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/calendarios',
      name: 'calendarios',
      component: Calendarios,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/packs',
      name: 'packs',
      component: Packs,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/definicoes',
      component: Definicoes,
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: 'utilizadores', component: Utilizadores },
        { path: 'planos', component: Planos },
        { path: 'empresa', component: Empresa },
        { path: 'planos-historico', component: PlanosHitorico },
        { path: '', redirect: '/definicoes/empresa' }
      ]
    },
    {
      path: '/utilizadores/criar',
      name: 'UtilizadoresCriar',
      component: UtilizadoresCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/utilizadores/editar/:id',
      name: 'UtilizadoresEditar',
      component: UtilizadoresCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/templates',
      name: 'templates',
      component: Templates,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/aniversarios',
      name: 'aniversarios',
      component: Aniversarios,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/historico',
      name: 'historico',
      component: Historico,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: Newsletter,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/evento',
      name: 'evento',
      component: evento,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/evento/:id',
      name: 'eventoID',
      component: evento,
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/balizas',
      name: 'balizas',
      component: Balizas,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizascriar',
      name: 'balizasCriar',
      component: BalizasCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizascriar/:id',
      name: 'balizasCriarID',
      component: BalizasCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizaslandia',
      name: 'balizaslandia',
      component: Balizaslandia,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizaslandiaLivre',
      name: 'balizaslandiaLivre',
      component: balizaslandiaLivre,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizaslandiacriar',
      name: 'balizaslandiaCriar',
      component: BalizaslandiaCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/balizaslandiacriar/:id',
      name: 'balizaslandiaCriarID',
      component: BalizaslandiaCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/distribuicao',
      name: 'distribuicao',
      component: Distribuicao,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/pagamentos',
      name: 'pagamentos',
      component: Pagamentos,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/monitores1',
      name: 'monitores1',
      component: Monitores1,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/monitores',
      name: 'monitores',
      component: Monitores,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/monitores/criar',
      name: 'monitoresCriar',
      component: MonitoresCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/monitores/editar/:id',
      name: 'monitoresEditar',
      component: MonitoresCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios',
      name: 'relatorios',
      component: Relatorios,
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: NotFound
    },
    {
      path: '*',
      redirect: '/notfound'
    }
  ],
  mode: 'history',
  hashbang: false,
  base: __dirname,
  transitionOnLoad: true,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

Vue.use(require('@websanova/vue-auth'), {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  rolesVar: 'roles',
  loginData: { url: './login', method: 'POST', redirect: '', fetchUser: false },
  registerData: { url: './register', method: 'POST', redirect: '', fetchUser: false },
  logoutData: { url: './logout', method: 'POST', redirect: './login', makeRequest: true },
  refreshData: { url: './refresh', method: 'GET', enabled: false, interval: 999999999999, error: function () { window.localStorage.clear(); window.location = './login' } },
  fetchData: { enabled: false },
  authRedirect: { path: './login' },
  tokenDefaultName: 'bearer',
  tokenStore: ['localStorage', 'cookie'],
  token: [{ request: 'Authorization', response: 'Authorization', authType: 'bearer', foundIn: 'header' }, { request: 'token', response: 'token', authType: 'bearer', foundIn: 'response' }]
})

const router = Vue.router

Vue.filter('data', dta => {
  if (!dta) {
    return ''
  }
  let dta1 = dta.split('-')
  let data = new Date()
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('hoje')
  }
  data.setDate(data.getDate() - 1)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) + 1 && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('ontem')
  }
  data.setDate(data.getDate() + 2)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) + 1 && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('amanha')
  }
  return parseInt(dta1[2]) + ' ' + Vue.i18n.translate('meses.' + parseInt(dta1[1])).substring(0, 3).toUpperCase() + ' ' + dta1[0].substring(2, 4)
  // (parseInt(data.getFullYear()) !== parseInt(dta1[0]) ? ' ' + dta1[0].substring(2, 4) : '')
})
Vue.filter('datahora', dta => {
  if (!dta) {
    return ''
  }
  let dta1 = dta.split('-')
  let data = new Date()
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('hoje') + ' ' + dta.substring(11, 16)
  }
  data.setDate(data.getDate() - 1)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) + 1 && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('ontem') + ' ' + dta.substring(11, 16)
  }
  data.setDate(data.getDate() + 2)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth()) + 1 && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('amanha') + ' ' + dta.substring(11, 16)
  }
  return parseInt(dta1[2]) + ' ' + Vue.i18n.translate('meses.' + parseInt(dta1[1])).substring(0, 3).toUpperCase() + ' ' + dta1[0].substring(2, 4) + ' ' + dta.substring(11, 16)
  // (parseInt(data.getFullYear()) !== parseInt(dta1[0]) ? ' ' + dta1[0].substring(2, 4) : '')
})
Vue.filter('hora', hora => {
  return hora.substring(0, 5)
})
Vue.filter('principal', (id, ar, campo) => {
  if (campo) {
    return ar.find(el => {
      return el.id === id
    })[campo]
  } else {
    return ar.find(el => {
      return el.id === id
    })[campo]
  }
})

Vue.directive('decimal', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
  /*
  componentUpdated: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
  */
})
Vue.directive('numerico', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseInt(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (a.target.value > 0) {
        a.target.value = parseInt(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    e.value = e.value.replace(/\D/g, '')
  }
})
Vue.directive('alfanumerico', {
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^[A-Za-z0-9]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('texto', {
  update: function (e) {
    let a = e.value
    let reg = /^[0-9A-Z a-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ~^´`¨°"\s|.ºª,\\!#$%:;|/&/()=?*€@*+_'<>-]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('mail', {
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^([A-Za-z0-9_.-]*|[A-Za-z0-9_.-]*@|[A-Za-z0-9_.-]*@[A-Za-z0-9_.-]*)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})

new Vue({
  router,
  // i18n,
  store,
  render: h => h(App)
}).$mount('#app')
