<template>
  <div class="row pr cinzento1 m-0">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false" style="max-height: 100vh;overflow-y: auto;">
      <div class="panel m-0" v-if="eventoInfo.id">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <div class="clearfix"></div>
          <h3 class="panel-title text-uppercase texto-branco text-center">{{form.nome}}</h3>
        </div>
        <div class="panel-body pt-40 row">
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Estado Lanche</span>
              <select class="form-control foco" v-model="form.lanche">
                <option value="" selected>Não iniciado</option>
                <option value="2">A Decorrer</option>
                <option value="3">Terminado</option>
              </select>
            </div>
          </div>
          <div class="form-group col-lg-12">
            <div class="input-group">
              <span class="input-group-addon">Observações Lanche</span>
              <textarea row="3" type="text" class="form-control" v-model="form.ol"></textarea>
            </div>
          </div>

          <a @click="atualizar()" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar</a>
          <a @click="$modal.hide('opcoesEventos');" class="btn btn btn-block btn-default" href="javascript:void(0)">Cancelar</a>
        </div>
      </div>
    </modal>
    <div class="cinzento" style="width:100%">
      <a class="icon wb-power float-right m-10" href="javascript:void(0)" @click="logout()" >
        <span class="sr-only">Sair</span>
      </a>
      <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:68px;" />
      <span style="color:#fff;font-size:20px;">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>



      <button v-if="amanha == 0" @click="trocarDia(1)" class="float-right mr-10 btn btn-warning">Amanhã</button>
      <button v-else @click="trocarDia(0)" class="float-right mr-10 btn btn-warning">Hoje</button>

      <button v-if="!ocultas" @click="ocultas = 1" class="btn btn-success float-right mr-10">Ver Lanches Concluídos</button>
      <button v-else @click="ocultas = 0" class="btn btn-success float-right mr-10">Voltar</button>

      <span class="float-right mr-10 texto-branco mt-5" style="font-size: 21px;" v-text="horas"></span>
    </div>
    <div class="cinzento1" style="width:100%">
      <div class="table-responsive cinzento1">
        <table id="tabelaFixa" class="table balizas lanche">
          <thead>
            <tr>
              <th style="top: 0px;width:50px;" class="meio azul text-center"></th>
              <th style="top: 0px;width:50px;" class="meio azul text-center">Hora Lanche</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Disp.</th>
              <th style="top: 0px;width:60px;" class="meio azul text-center">Cor</th>
              <th style="top: 0px;width:150px;" class="meio azul text-center">Aniversariante</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Nº C Conf.</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Nº C Total</th>
              <th style="top: 0px;width:150px;" class="meio azul text-center">Menu</th>
              <th style="top: 0px;width:300px;" class="meio azul text-center">Extras Bolo</th>
              <th style="top: 0px;width:300px;" class="meio azul text-center">Extras Lanche</th>
              <th style="top:0px;" class="meio azul text-center">Observações Lanche</th>
              <th style="top:0px;" class="meio azul text-center">Observações Cacifo</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Idade</th>
              <td style="top: 0px;width:300px;" rowspan="100%">
                <table class="table balizas">
                  <tbody style="color:#000;">
                    <tr>
                      <th class="meio azul text-center" style="color:#fff; top: 0px">Criança(s)</th>
                      <th class="meio azul text-center" style="color:#fff; top: 0px">Hora<br />Saída</th>
                      <th class="meio azul text-center" style="color:#fff; top: 0px">OBS</th>
                    </tr>
                    <tr v-for="(dt, index) in res1" :key="index" @click="upData(dt)" :class="'mao ' + (meta(dt, 'Lanche') == 3 ? 'cinzento1' : '')" v-show="showFN(dt, index)">
                      <td class="text-center meio" style="background: #ffffd0">
                        {{meta(dt, 'Crianças')}}
                      </td>
                      <td class="meio text-left" style="background: #ffffd0">{{parseInt(dt.event_at.split(' ')[1]) + parseInt(meta(dt, 'tempo')) + dt.event_at.split(' ')[1].substring(2, 5)}}</td>
                      <td class="meio text-center" style="background: #ffffd0"><a class="text-danger" v-if="meta(dt, 'Observações Lanche')">Ver</a></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr v-for="(dt, index) in res" :key="index" :class="'mao ' + (meta(dt, 'Exterior') ? 'exterior' : '') + ((dt.event_at.split(' ')[1] == '14:00:00' && !dt.ocultar || dt.event_at.split(' ')[1] == '17:00:00' && !dt.ocultar || dt.event_at.split(' ')[1] == '11:30:00' && !dt.ocultar) ? ' border-verm ' : '') + (meta(dt, 'Lanche') == 3 ? 'cinzento1' : '')" @click="upData(dt)" v-show="showFN(dt, index)">
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center"><div :class="{'c-amarelo': meta(dt, 'Lanche') == 2, 'c-verde': meta(dt, 'Lanche') == 3 }"></div></td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{meta(dt, 'Hora do Lanche') || (dt.tipo === 'cal' ? '' : lancheFN(dt.event_at.split(' ')[1]))}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center">{{meta(dt, 'Nº Dispositivo') == 'Nº Dispositivo' ? 1 : meta(dt, 'Nº Dispositivo') || (dt.tipo === 'cal' ? '' : dispositivoFN(dt.event_at.split(' ')[1]))}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" @click="dt.id ? upData(dt): $router.push('/balizaslandiacriar' + (dt.id ? '/' + dt.id : '?d=' + dt.event_at))" style="color:#ddd" :class="'text-center hidden-sm-down cinzento1 ' + (dt.tipo === 'cal' ? '' : (meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1])))"></td>
              <td class="meio">
                <label class="break" v-if="meta(dt, 'menu') == 'Centro Estudos'">{{dt.eventable.name}}</label>
                <label class="break" v-html="meta(dt, 'Filho 1')" v-if="meta(dt, 'Filho 1') && meta(dt, 'check1')"></label>
                <label class="break" v-html="meta(dt, 'Filho 2')" v-if="meta(dt, 'Filho 2') && meta(dt, 'check2')"></label>
                <label class="break" v-html="meta(dt, 'Filho 3')" v-if="meta(dt, 'Filho 3') && meta(dt, 'check3')"></label>
                <label class="break" v-html="meta(dt, 'Filho 4')" v-if="meta(dt, 'Filho 4') && meta(dt, 'check4')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center">{{meta(dt, 'Nº Crianças Confirmadas')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center azul1"><b>{{meta(dt, 'Total Crianças')}}</b></td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" :class="{'meio': 1, 'border-red': almocoJantarFN(dt)}">{{meta(dt, 'menu')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="extras-null meio">
                <label class="break" v-html="meta(dt, 'Hóstia bolo', 'hostia_tema')" v-if="meta(dt, 'Hóstia bolo', 'hostia_tema')"></label>
                <label class="break" v-html="meta(dt, 'Bolo artístico', 'bolo_tema')" v-if="meta(dt, 'Bolo artístico', 'bolo_tema')"></label>

                <label class="break" v-html="boloTemaFN(dt)" v-if="meta(dt, 'Bolo')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="extras-null meio">
                <label class="break" v-html="meta(dt, 'Festa temática', 'festa_tema')" v-if="meta(dt, 'Festa temática', 'festa_tema')"></label>
                <label class="break" v-html="meta(dt, 'Pipocas')" v-if="meta(dt, 'Pipocas')"></label>
                <label class="break" v-html="meta(dt, 'Crossaints')" v-if="meta(dt, 'Crossaints')"></label>
                <label class="break" v-html="meta(dt, 'Pizzas')" v-if="meta(dt, 'Pizzas')"></label>
                <label class="break" v-html="meta(dt, 'Donuts')" v-if="meta(dt, 'Donuts')"></label>
                <label class="break" v-html="meta(dt, 'Nuggets')" v-if="meta(dt, 'Nuggets')"></label>
                <label class="break" v-html="meta(dt, 'Iorgurtes')" v-if="meta(dt, 'Iorgurtes')"></label>
                <label class="break" v-html="meta(dt, 'Fruta da época')" v-if="meta(dt, 'Fruta da época')"></label>
                <label class="break" v-html="meta(dt, 'Mini muffins')" v-if="meta(dt, 'Mini muffins')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{meta(dt, 'Observações Lanche')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{meta(dt, 'Observações Cacifo')}}</td>
              <td class="text-center meio">
                <label class="break" v-html="meta(dt, 'Anos')" v-if="meta(dt, 'Anos') && meta(dt, 'check1')"></label>
                <label class="break" v-html="meta(dt, 'Anos 2')" v-if="meta(dt, 'Anos 2') && meta(dt, 'check2')"></label>
                <label class="break" v-html="meta(dt, 'Anos 3')" v-if="meta(dt, 'Anos 3') && meta(dt, 'check3')"></label>
                <label class="break" v-html="meta(dt, 'Anos 4')" v-if="meta(dt, 'Anos 4') && meta(dt, 'check4')"></label>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent, patchEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageLanche',
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      eventoInfo: {},
      form: {
        nc: '',
        nt: '',
        nd: '',
        cor: '',
        hora_lanche: '',
        observacoes: ''
      },
      scrollable: false,
      amanha: 0,
      ocultas: 0,
      res1: [],
      horas: ''
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    this.init()
    this.relogio()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    relogio () {
      let dd = new Date()
      this.horas = dd.getHours() + ':' + ('0' + dd.getMinutes()).slice(-2)
      _.delay(function (a) {
        a.relogio()
      }, 1000, this)
    },
    boloTemaFN (dt) {
      switch (this.meta(dt, 'Bolo')) {
        case 'Bolo 1Kg':
        case 'Bolo 2Kg':
        case 'Bolo Artístico':
          if (this.meta(dt, 'bolo_tema1')) {
            return (this.meta(dt, 'bolo_qtd')  || 1) + ' x ' + this.meta(dt, 'Bolo') + ' -> ' + this.meta(dt, 'bolo_tema1')
          }
          return (this.meta(dt, 'bolo_qtd')  || 1) + ' x ' + this.meta(dt, 'Bolo') + ' -> Tema a confirmar'
      }
      return this.meta(dt, 'Bolo') + (this.meta(dt, 'bolo_tema1') ? ' -> ' + this.meta(dt, 'bolo_tema1') : '')
    },
    init () {
      this.formUrl('', 1)
    },
    trocarDia (a) {
      this.amanha = a
      var dd = new Date()
      dd.setDate(dd.getDate() + a)
      this.year = dd.getFullYear()
      this.month = ('0' + (1 + dd.getMonth())).slice(-2)
      this.day = ('0' + dd.getDate()).slice(-2)
      this.formUrl('', 1)
    },
    formUrl () {
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.user.email) {
        case 'lanche.smf@balizaslandia.pt':
          cal = [6, 67]
          break
        case 'lanche.oaz@balizaslandia.pt':
          cal = [107, 110]
          break
      }
      searchEvent(this.axios, cal, this.year, this.month, '', '', '', this.day, '').then((res) => {
        this.res = res.data.filter(a => !this.meta(a, 'Bloqueado') && a.meta[0] && parseInt(a.calendar_id) === cal[0] && this.meta(a, 'menu') !== 'Centro Estudos').sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        // .sort((a, b) => this.meta(a, 'Hora do Lanche').replace(/:/g, '') - this.meta(b, 'Hora do Lanche').replace(/:/g, ''))
        this.res1 = res.data.filter(a => parseInt(a.calendar_id) === cal[1] && this.meta(a, 'Lanche1') && this.meta(a, 'menu') !== 'Centro Estudos')
        this.res1 = this.res1.sort((a, b) => {
          let x = parseInt(a.event_at.split(' ')[1]) + parseInt(this.meta(a, 'tempo')) + a.event_at.split(' ')[1].substring(3, 5)
          let y = parseInt(b.event_at.split(' ')[1]) + parseInt(this.meta(b, 'tempo')) + b.event_at.split(' ')[1].substring(3, 5)
         
          if (x > y) {
            return 1
          }
          if (x < y) {
            return -1
          }
          return 0
        })
        this.SET_LOAD()
        /*
        _.delay(function (a) {
          a.formUrl()
        }, 100000, this)
        */
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    almocoJantarFN (dt) {
      let a = dt.meta.find(a => a.meta_key === 'menu')
      switch (a.meta_value) {
        case 2:
        case 12:
        case 5:
        case 8:
        case 9:
        case 15:
        case 14:
          return true
      }
      return false
    },
    meta (dt, campo, campo1, virgula) {
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (a.meta_key === 'menu') {
            switch (a.meta_value) {
              case 6:
                return 'Normal'
              case 10:
                return 'Normal'
              case 1:
                return 'Premium'
              case 11:
                return 'Premium'
              case 2:
                return 'Normal Almoço/Jantar'
              case 12:
                return 'Normal Almoço/Jantar'
              case 3:
                return 'Normal'
              case 4:
                return 'Premium'
              case 5:
                return 'Normal Almoço/Jantar'
              case 7:
                return 'Centro Estudos'
              case 8:
                return 'Premium Almoço/Jantar'
              case 9:
                return 'Premium Almoço/Jantar'
              case 13:
                return 'Normal'
              case 15:
                return 'Normal Almoço/Jantar'
              case 14:
                return 'Normal Almoço/Jantar'
              case 16:
                return 'Normal'
            }
          }
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'Prolongamento festa +30m' && a.meta_key !== 'Prolongamento festa (+1h)' && a.meta_key !== 'Caução Paga' && a.meta_key !== 'tempo' && a.meta_key !== 'Total Crianças' && a.meta_key !== 'Anos' && a.meta_key !== 'Anos 2' && a.meta_key !== 'Anos 3' && a.meta_key !== 'Anos 4' && a.meta_key !== 'bolo_qtd') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                return a.meta_key + ' -> ' + (b.meta_value || 'tema a confirmar') + (virgula && a.meta_value ? '/ ' : '')
              }
            }
            return a.meta_key + (virgula && a.meta_key ? '/ ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? '/ ' : '')
          }
        }
      }
      return ''
    },
    linhasFN (dt, index) {
      let t = this.res.length - 1
      if (index < (t - 1)) {
        if (this.res[index + 1].event_at === dt.event_at && this.res[index + 2].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          this.res[index + 2].ocultar = 1
          return 3
        }
      }
      if (index < t) {
        if (this.res[index + 1].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          return 2
        }
      }
      return 1
    },
    upData (dt) {
      if (!dt.id) {
        return
      }
      let crianca = ''
      if (this.meta(dt, 'menu') === 'Centro Estudos') {
        crianca = dt.eventable.name
      } else {
        if (this.meta(dt, 'Filho 1') && this.meta(dt, 'check1')) {
          crianca += this.meta(dt, 'Filho 1')
        }
        if (this.meta(dt, 'Filho 2') && this.meta(dt, 'check2')) {
          crianca += ', ' + this.meta(dt, 'Filho 2')
        }
        if (this.meta(dt, 'Filho 3') && this.meta(dt, 'check3')) {
          crianca += ', ' + this.meta(dt, 'Filho 3')
        }
        if (this.meta(dt, 'Filho 4') && this.meta(dt, 'check4')) {
          crianca += ', ' + this.meta(dt, 'Filho 4')
        }
      }
      this.$modal.show('opcoesEventos')
      this.eventoInfo = dt
      this.form = {
        ol: this.meta(dt, 'Observações Lanche'),
        lanche: this.meta(dt, 'Lanche'),
        nome: crianca
      }
    },
    atualizar () {
      this.SET_LOAD(true)
      patchEventClient(this.axios, {
        id: this.eventoInfo.id,
        calendar_id: this.eventoInfo.calendar_id,
        color: this.eventoInfo.color,
        event_at: this.eventoInfo.event_at.substring(0, 16),
        meta: {
          'Observações Lanche': this.form.ol,
          'Lanche': this.form.lanche
        }
      }).then(() => {
        this.SET_TOAST({ msg: 'Atualizado com sucesso.' })
        this.SET_LOAD()
        this.$modal.hide('opcoesEventos')
        this.formUrl('', 1)
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
        }
      })
    },
    lancheFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return '10:00'
          case '09:01:00':
            return '10:15'
          case '09:30:00':
            return '10:30'
          case '09:45:00':
            return '10:45'
          case '10:00:00':
            return '11:00'
          case '10:30:00':
            return '11:30'
          case '11:30:00':
            return '12:00'
          case '11:31:00':
            return '12:15'
          case '12:00:00':
            return '12:30'
          case '12:30:00':
            return '13:00'
          case '12:45:00':
            return '13:15'
          case '14:00:00':
            return '15:00'
          case '14:01:00':
            return '15:15'
          case '14:15:00':
            return '15:30'
          case '14:30:00':
            return '15:45'
          case '15:30:00':
            return '16:45'
          case '17:00:00':
            return '17:30'
          case '17:15:00':
            return '18:00'
          case '17:30:00':
            return '18:30'
          case '18:00:00':
            return '19:00'
          case '18:01:00':
            return '19:10'
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return '10:00'
          case '09:15:00':
            return '10:15'
          case '09:30:00':
            return '10:30'
          case '09:45:00':
            return '10:45'
          case '10:00:00':
            return '11:00'
          case '10:30:00':
            return '11:30'
          case '11:30:00':
            return '12:00'
          case '11:45:00':
            return '12:15'
          case '12:00:00':
            return '12:30'
          case '12:30:00':
            return '13:00'
          case '12:45:00':
            return '13:15'
          case '14:00:00':
            return '15:00'
          case '14:15:00':
            return '15:30'
          case '14:30:00':
            return '15:45'
          case '15:00:00':
            return '16:00'
          case '15:30:00':
            return '16:45'
          case '17:00:00':
            return '17:30'
          case '17:15:00':
            return '18:00'
          case '17:30:00':
            return '18:30'
          case '17:45:00':
            return '19:00'
          case '18:00:00':
            return '19:10'
        }
      }
    },
    dispositivoFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return 1
          case '09:01:00':
            return 2
          case '09:30:00':
            return 3
          case '09:45:00':
            return 4
          case '10:00:00':
            return 5
          case '10:30:00':
            return 6
          case '11:30:00':
            return 7
          case '11:31:00':
            return 8
          case '12:00:00':
            return 9
          case '12:30:00':
            return 10
          case '12:45:00':
            return 11
          case '14:00:00':
            return 12
          case '14:01:00':
            return 13
          case '14:15:00':
            return 14
          case '14:30:00':
            return 15
          case '15:30:00':
            return 16
          case '17:00:00':
            return 17
          case '17:15:00':
            return 18
          case '17:30:00':
            return 19
          case '18:00:00':
            return 20
          case '18:01:00':
            return 21
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return 1
          case '09:15:00':
            return 2
          case '09:30:00':
            return 3
          case '09:45:00':
            return 4
          case '10:00:00':
            return 5
          case '10:30:00':
            return 6
          case '11:30:00':
            return 7
          case '11:45:00':
            return 8
          case '12:00:00':
            return 9
          case '12:30:00':
            return 10
          case '12:45:00':
            return 11
          case '14:00:00':
            return 12
          case '14:15:00':
            return 13
          case '14:30:00':
            return 14
          case '15:00:00':
            return 15
          case '15:30:00':
            return 16
          case '17:00:00':
            return 17
          case '17:15:00':
            return 18
          case '17:30:00':
            return 19
          case '17:45:00':
            return 20
          case '18:00:00':
            return 21
        }
      }
    },
    coresFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:01:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '11:30:00':
            return 'Vermelho'
          case '11:31:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:01:00':
            return 'Laranja'
          case '14:15:00':
            return 'Preto'
          case '14:30:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '18:00:00':
            return 'Castanho'
          case '18:01:00':
            return 'Roxo'
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:15:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '11:30:00':
            return 'Vermelho'
          case '11:45:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:15:00':
            return 'Laranja'
          case '14:30:00':
            return 'Preto'
          case '15:00:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '17:45:00':
            return 'Castanho'
          case '18:00:00':
            return 'Roxo'
        }
      }
    },
    corFN (a) {
      switch (parseInt(a)) {
        case 2:
          return 'c-amarelo'
        case 3:
          return 'c-verde'
      }
      return ''
    },
    showFN (dt, index) {
       if (index) {
        let i = index
        while (this.res[--i]) {
          if (dt.event_at === this.res[i].event_at) {
            if (this.res[i - 1]) {
              if (dt.event_at === this.res[i - 1].event_at) {
                return !this.meta(this.res[i - 1], 'Lanche') && !this.ocultas || parseInt(this.meta(this.res[i - 1], 'Lanche')) === 2 && !this.ocultas || parseInt(this.meta(this.res[i - 1], 'Lanche')) === 3 && this.ocultas
              }
            }
            return !this.meta(this.res[i], 'Lanche') && !this.ocultas || parseInt(this.meta(this.res[i], 'Lanche')) === 2 && !this.ocultas || parseInt(this.meta(this.res[i], 'Lanche')) === 3 && this.ocultas
          }
        }
      }
       return !this.meta(dt, 'Lanche') && !this.ocultas || parseInt(this.meta(dt, 'Lanche')) === 2 && !this.ocultas || parseInt(this.meta(dt, 'Lanche')) === 3 && this.ocultas
    }
  }
}
</script>
